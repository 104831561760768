<template>
  <div class="elv-upload-container">
    <el-upload
      v-loading="uploadLoading"
      class="elv-upload"
      :action="uploadUrl"
      :show-file-list="false"
      :headers="{
        Authorization: Authorization
      }"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :on-error="handleError"
      @progress="onUploadProgress"
    >
      <div v-if="imgUrl" class="elv-preview-container">
        <img :src="imgUrl" alt="预览图" />
      </div>
      <div v-else class="upload-placeholder">
        <SvgIcon name="create-project" width="24" height="24" />
      </div>
    </el-upload>
  </div>
</template>
<script setup lang="ts">
import { useCookies } from 'vue3-cookies'
import { ElMessage, UploadProps } from 'element-plus'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  }
})

const { t } = useI18n()
const { cookies } = useCookies()

const emit = defineEmits(['update:modelValue'])

const imgUrl = ref()
const uploadLoading = ref(false)
const uploadUrl = `${import.meta.env.VITE_API_URL}/project/upload` // 上传接口地址

const Authorization = `Bearer ${cookies.get('elv-app-token')}`

/**
 * @description: 上传前校验
 * @param {File} file
 * @return {boolean}
 */
const beforeUpload: UploadProps['beforeUpload'] = (file) => {
  const isJPG = file.type === 'image/jpeg'
  const isPNG = file.type === 'image/png'
  const isLt2M = file.size / 1024 / 1024 < 2

  if (!isJPG && !isPNG) {
    ElMessage.error('图片格式必须为 JPG/PNG 格式!')
    return false
  }
  if (!isLt2M) {
    ElMessage.error('上传图片大小不能超过 2MB!')
    return false
  }
  return true
}

/**
 * @description: 上传成功回调
 * @param {Response} response
 */
const handleSuccess: UploadProps['onSuccess'] = (response) => {
  const res = response.data
  if (response.status === 'success') {
    ElMessage.success(t('message.uploadSuccess'))
    imgUrl.value = res.url
    uploadLoading.value = false
    emit('update:modelValue', imgUrl.value)
  } else {
    ElMessage.error(res.msg)
  }
}

/**
 * @description: 上传失败回调
 * @param {*} err
 * @return {*}
 */
const handleError: UploadProps['onError'] = (err) => {
  console.log(err)
  uploadLoading.value = false
  ElMessage.error(t('message.uploadError'))
}

/**
 * @description: 上传进度回调
 */
const onUploadProgress = () => {
  uploadLoading.value = true
}

watch(
  () => props.modelValue,
  (val) => {
    if (val && val !== imgUrl.value) {
      imgUrl.value = val
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
.elv-upload-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.elv-upload {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.elv-preview-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.elv-preview-container img {
  max-width: 100%;
  max-height: 100%;
}

.upload-placeholder {
  width: 70px;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #c0c4cc;
  font-size: 14px;
  cursor: pointer;
  background: #f9fafb;
  border: 1px solid #dde1e6;
  border-radius: 4px;

  svg {
    fill: #838d95;
  }
}

.elv-upload-icon {
  font-size: 24px;
}
</style>
